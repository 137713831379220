import { mapState } from 'vuex'
import { currencyFormat } from '@/helpers'

const { shuffleArray } = Tui.utils

export default {
  name: 'TFilesBankSlip',
  title: ({ title }) => title,
  components: {
    TBankslipTable: () => import('@/components/files/table-bankslip.vue'),
    TFilter: () => import('@/components/filter')
  },
  data () {
    return {
      title: `${this.$tc('Debit')} - ${this.$t('BillingFiles')}`,
      bankslips: shuffleArray([...Array(20)].map((v, i) => ({
        id: faker.random.uuid(),
        download: shuffleArray([faker.date.between('2019-08-01', '2019-07-01'), 'Pendente'])[0],
        generatedDate: faker.date.between('2019-08-01', '2019-07-01'),
        dueDate: faker.date.between('2019-08-01', '2019-07-01'),
        billetQty: faker.random.number(999),
        totalAmount: currencyFormat(faker.random.number(9999))
      }))),
      totalAmountSelected: 0,
      total: 10,
      limit: 10,
      formFilter: {},
      isLoading: false,
      // para testes, dado vem do metadata do endpoint
      totalPages: 5,
      currentPage: 1,
      pagesSelected: 1,
    }
  },
  computed: {
    ...mapState({
      selectedAmountData: ({ bankslip }) => bankslip.totalSelected,
      months: ({ bankslip }) => bankslip.months,
      years: ({ bankslip }) => bankslip.years
    }),
    exportButtonText () {
      if ( this.selectedAmountData > 0 ) {
        return `Exportar ${this.selectedAmountData} datas selecionadas`
      } else {
        return `Exportar todos os pendentes`
      }
    }
  },
  methods: {
    prevPage () {
    },
    nextPage () {
    },
    goToPage (pageNumber) {
    },
    bindPlugins () {
      $('.tui.popup').popup({
        transition: 'fade'
      })
    }
  },
  mounted () {
    this.bindPlugins()
  }
}
