import { mapState, mapActions } from 'vuex'
import { getMonth, getYear } from '@/helpers'
import { getWalletsList } from '@/queries/files/file.queries'
import filesMixin from '@/mixins/files.mixin'

export default {

  name: 'TFilesPage',

  title: ({ title }) => title,

  mixins: [ filesMixin ],

  components: {
    TFilesSidebar: () => import ('@/components/files/sidebar.vue'),
    TFilesTableFinished: () => import ('@/components/files/table-finished.vue'),
    TFilesTableNewShipment: () => import ('@/components/files/table-new-shipment.vue'),
    TFilesTableWaitingReturn: () => import ('@/components/files/table-waiting-return.vue'),
    TBarStatus: () => import ('@/components/files/bar-status.vue'),
    TFileUpload: () => import ('@/components/files/upload-file.vue')
  },

  data () {
    return {
      title: `${this.$tc('Debit')} - ${this.$t('BillingFiles')}`,
      filesUploaded: [],
      wallets: [],
      banks: [],
      bankFiles: {},
      monthlyMetrics: {},
      randomGeneratedBank: {},
      getMonth,
      getYear,
    }
  },

  computed: {
    ...mapState({
      isLoading: ({ files }) => files.listLoading,
      widgetList: ({ files }) => files.widgetList,
      fileHasErrors: ({ files }) => files.fileHasErrors,
      processCompleted: ({ files }) => files.processCompleted,
      progress: ({ files }) => files.progress,
      filesNewShipment: ({ files }) => files.fileListNewShipment,
      filesWaitingReturn: ({ files }) => files.fileListWaitingReturn,
      filesFinished: ({ files }) => files.fileListFinished,
      currentYear: ({ files }) => files.currentYear,
      currentMonty: ({ files }) => files.currentMonty,
      loadingNewShipment: ({ files }) => files.loadingNewShipment,
      loadingWaitingReturn: ({ files }) => files.loadingWaitingReturn,
      loadingFinished: ({ files }) => files.loadingFinished,
    }),

    processing () {
      return ( this.progress !== 0 && this.progress < 100 )
    },

    fileNameUpload () {
      const { files } = this.$store.state
      let nameFile = ''
      if ( files.fileData ) {
        nameFile = files.fileData.name
      } else {
        nameFile = ""
      }

      if ( this.processCompleted ) {
        if ( this.progress === 100 && this.fileHasErrors && files.fileData.file ) {
          return `Erro na importação do retorno do arquivo <b>${nameFile}</b>.`
        } else if ( this.progress === 100 && this.fileHasErrors && !files.fileData.file ) {
          return `Erro na geração do arquivo <b>${nameFile}</b>.`
        } else if ( this.progress === 100 && !this.fileHasErrors && files.fileData.file ) {
          return `Retorno do arquivo <b>${nameFile}</b> importado com sucesso.`
        } else {
          return `Arquivo <b>${nameFile}</b> criado com sucesso.`
        }
      } else if ( files.fileData.file == null ) {
        return `Gerando o arquivo <b>${nameFile}</b>...`
      } else if ( this.processing ) {
        return `Importando arquivo <b>${nameFile}</b>...`
      } else {
        return ''
      }
    },

    orderedWidgetList () {
      return this.widgetList.length > 0 ? this.widgetList.sort((a, b) => b.year - a.year) : this.widgetList
    },

    isCurrentPeriod () {
      return getMonth() === this.currentMonty && getYear() === this.currentYear
    }
  },

  methods: {
    ...mapActions('files', ['getFiles', 'getFilesNewShipment', 'getFilesWaitingReturn',
      'getFilesFinished', 'toggleLoadingBarStatus', 'toggleFileHasErrors', 'getYearListFiles',
      'toggleProcessCompleted', 'setUploadFileProgress', 'toggleLoadingWidget', 'setWalletFilter',
      'setCurrentYear', 'setCurrentMonth'
    ]),
    ...mapActions('alert', ['showAlert', 'hideAlert', 'changeMessage']),
    ...mapActions('breadcrumb', ['changeBreadcrumbItems']),
    ...mapActions('notifications', [ 'toggleNotification', 'setNotificationMessage' ]),
    ...mapState({
      showBarStatus: ({ files }) => files.showBarStatus,
      hasButtonBarStatus: ({ files }) => files.hasButtonBarStatus
    }),

    isFilesFilled () {
      return (this.isCurrentPeriod && this.filesNewShipment.length > 0) || this.filesWaitingReturn.length > 0 || this.filesFinished.length > 0
    },

    bindPlugins () {
      $('.tui.ui.dropdown').dropdown()
      $('.tui.popup').popup({
        transition: 'fade'
      })
    },

    getBankFiles () {
      this.getFilesNewShipment()
      this.getFilesWaitingReturn( { year: this.currentYear, month: this.currentMonty } )
      this.getFilesFinished( { year: this.currentYear, month: this.currentMonty } )
    },

    onUploadFileChange ( file ) {
      const { commit } = this.$store
      commit('files/SET_BUTTON_BAR_STATUS', false)
      commit('files/SET_FILE_DATA', { file: file, name: file.name })
      this.closeUploadStatus()
    },

    onUploadFileSucess ( e ) {
      this.error = ''
      this.success = true
      this.toggleFileHasErrors(false)
      this.toggleProcessCompleted(true)
    },

    onUploadFileError ( e ) {
      this.error = e
      this.toggleFileHasErrors(true)
      this.toggleProcessCompleted(true)
    },

    uploadProgress ( progress ) {
      if (this.progress === 0) {
        this.toggleLoadingBarStatus( true )
      }
      this.setUploadFileProgress( progress )
    },

    closeUploadStatus () {
      this.toggleFileHasErrors( false )
      this.toggleLoadingBarStatus( false )
      this.setUploadFileProgress( 0 )
      this.toggleProcessCompleted( false )
    },

    loadBankListFilter () {
      getWalletsList().then( result => {
        const { collection } = result.data.wallets
        this.wallets = [
          { id: 0, bankName: 'Todas as carteiras' },
          ...collection
        ]
        this.erros = null
      }).catch( e => {
        this.erros = e
      })
    },

    reloadFilesData ( walletID ) {
        this.setWalletFilter( walletID )
        this.getBankFiles()
    }
  },

  mounted () {
      this.setCurrentYear( getYear() )
      this.setCurrentMonth( getMonth() )

      this.setWalletFilter( 0 )
      this.getBankFiles()
      this.bindPlugins()
      this.loadBankListFilter()
      this.getYearListFiles()

      this.verifyTimeLimitToExportFile()

      this.changeBreadcrumbItems([
          { to: { name: 'files', params: this.$route.params }, title: 'Arquivos de cobrança', active: true }
      ])
  }
}
