import { mapState, mapActions } from 'vuex'

import { checkDate } from '@/helpers'
import { getTransactions } from '@/queries/transactions/getTransactions'

export default {
  name: 'TFileSinglePage',

  components: {
    TFilter: () => import('@/components/filter') ,
    TStatusBar: () => import('@/components/status/bar'),
    TStatusBarItem: () => import('@/components/status/item.vue'),
    TTransactionsTable: () => import('@/components/transactions/table.vue'),
    TFilesSingleFilters: () => import('@/components/files/single/filters.vue')
  },

  title: ({ title }) => title,
  data () {
    return {
      title: this.$tc('File'),
      transactionId: '',
      transactionFiles: [],
      formFilter: {},
      loadingTableData: false,
      totalPages: "0",
      currentPage: "0",
      pagesSelected: "0"
    }
  },
  computed: {
    ...mapState({
      payments: ({ transactions }) => transactions.payments,
      metaDataPaymentsList: ({ transactions }) => transactions.metaDataPaymentsList,
      paymentMethods: ({ transactions }) => transactions.paymentMethods,
      statuses: ({ transactions }) => transactions.statuses,
      isLoading: ({ singleFile }) => singleFile.isLoading,
      filterItems: ({ singleFile }) => singleFile.filterItems,
      filters: ({ singleFile }) => singleFile.filters,
      filterForm: ({ singleFile }) => singleFile.filterForm,
      orderParams: ({ singleFile }) => singleFile.orderParams,
      headerData: ({ singleFile }) => singleFile.headerData
    }),

    formattedTitle: ({ headerData }) => `<span class="title-bank">${headerData.bank}</span> <small class="title-date"> - ${headerData.date}</small>`
  },
  methods: {
    ...mapActions('breadcrumb', [ 'changeBreadcrumbItems' ]),
    ...mapActions('filter', [ 'toggleLoading' ]),
    ...mapActions('singleFile', [ 'addItem', 'clearFilterForm', 'clearFilterItems', 'removeItem', 'setOrderParams', 'setHeaderData' ]),
    ...mapActions('transactions', [ 'setPaymentsList', 'setMetaDataPaymentsList' ]),
    bindjQueryPlugins () {
      $('.tui.status-bar').sticky({ offset: 120 })

      $('.tui.popup').popup({
        transition: 'fade'
      })
    },

    applyFilter () {
      const { filterBy, equalTo, fieldOne, fieldTwo } = this.filterForm

      if ( filterBy && equalTo && fieldOne ) {

        if (!this.filterItemAlreadySelected(filterBy)) {
          let allowAddItem = false
          if (filterBy.key === 'period'
            && fieldOne
            && !checkDate(fieldOne)
            && fieldTwo
            && !checkDate(fieldTwo)) {
            allowAddItem = true
          } else if ( filterBy.key === 'payment_method'
            && fieldOne) {
            allowAddItem = true
          } else if ( filterBy.key === 'status'
            && fieldOne) {
            allowAddItem = true
          }

          if (allowAddItem) {
            this.toggleLoading(true)
            setTimeout(() => {
              this.addItem({ filterBy, equalTo, fieldOne, fieldTwo })
              this.searchTransactions()
              this.clearFilterForm()
              $('.tui.ui.dropdown').dropdown('clear')
              this.toggleLoading(false)
            }, 1000)
          }
        }
      }
    },

    filterItemAlreadySelected (filterBy) {
      return this.filterItems.length !== 0 && this.filterItems.find( filter => filter.filterBy.key === filterBy.key )
    },

    searchTransactions (params = {}) {

      let searchParams = this.mountSearchParams()
      searchParams = {
        ...searchParams,
        ...params
      }

      this.loadingTableData = true
      getTransactions( searchParams ).then( res => {
        const { collection, metadata } = res.data.paymentIntents
        this.setPaymentsList(collection)
        this.setMetaDataPaymentsList(metadata)
        this.updateMetaDataTable()
        this.loadingTableData = false
      }).catch(e => {
        Vue.$log.error('error', e)
        this.loadingTableData = false
      })
    },

    prevPage () {
      if (this.currentPage === 1) return
      const page = Number(this.currentPage) - 1
      let params = { page }
      this.searchTransactions( params )
    },

    nextPage () {
      if (this.currentPage === this.totalPages) return
      const page = Number(this.currentPage) + 1
      let params = { page }
      this.searchTransactions( params )
    },

    goToPage (pageNumber) {
      const page = Number(pageNumber)
      if (Number(page) < 0 || Number(page) > Number(this.totalPages)) return
      let params = { page }
      this.searchTransactions( params )
    },

    updateMetaDataTable () {
      if ( this.metaDataPaymentsList ) {
        this.totalPages = `${this.metaDataPaymentsList.totalPages}`
        this.currentPage = `${this.metaDataPaymentsList.currentPage}`
        this.pagesSelected = `${this.metaDataPaymentsList.limitValue}`
        this.total = this.metaDataPaymentsList.totalCount
      }
    },

    mountSearchParams () {
      let params = {}
      params.outputProcessRequestId = this.transactionId
      if (this.filterItems.length > 0) {
        const paymentMethodFilter = this.filterItems.find( item => item.filterBy.key === 'payment_method' )
        const periodFilter = this.filterItems.find( item => item.filterBy.key === 'period' )
        const status = this.filterItems.find( item => item.filterBy.key === 'status' )

        if (paymentMethodFilter) {
          params.paymentMethod = paymentMethodFilter.fieldOne.key
        }

        if (status) {
          params.status = status.fieldOne.key
        }

        if (periodFilter) {
          params.createdAtBeginDate = periodFilter.fieldOne
          params.createdAtEndDate = periodFilter.fieldTwo
        }
      }

      if (this.limit) {
        params.limit = this.limit
      }

      if (this.orderParams) {
        params.orderBy = this.orderParams.orderBy
        params.order = this.orderParams.order
      }

      return params
    },

    filtersUpdated (item) {
      this.removeItem(item)
      this.searchTransactions()
    },

    orderBy (orderObject) {
      const { field, order } = orderObject
      this.setOrderParams({ orderBy: field, order })
      this.searchTransactions()
    },

    checkInvalidDate: (date) => checkDate(date)
  },

  mounted () {
    this.clearFilterItems()
    this.bindjQueryPlugins()
    const { id, bank, date } = this.$route.params
    if (bank && date) {
      this.setHeaderData({ bank, date })
    }
    this.transactionId = id
    this.searchTransactions()
  }
}
