import { getBRTimeNow } from '@/helpers'

const filesMixin = {

  methods: {

    exceededTimeLimitExportFile() {
      const hourNow = getBRTimeNow().substring(0, 2)
      return hourNow < 5 || hourNow >= 17
    },

    verifyTimeLimitToExportFile () {
      if (this.exceededTimeLimitExportFile()) {
        this.setNotificationMessage("Os arquivos de remessa só podem ser gerados entre 5:00h e 17:00h (horário de Brasília)")
        setTimeout(() => {
          this.toggleNotification({ active: true, type: 'error' })
        }, 1500)
      }
    }

  }
}

export default filesMixin
