var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "single-file" },
    [
      _c("t-page-header", {
        attrs: {
          actionsLeftAligned: true,
          rightSize: "",
          leftSize: "fourteen",
          title: _vm.formattedTitle,
          popup:
            "<div class='content'><p>Ao contrário das doações em cartão de crédito, que são processadas de forma instantânea e automática, as doações via débito em conta precisam ser autorizadas pelos bancos. Para isso, você precisa enviar os arquivos de cobrança para os bancos. Nessa página, você pode visualizar quais registros e valores estão presentes no arquivo de cobrança selecionado.</p></div>"
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    staticClass: "ui icon basic button go-back",
                    on: {
                      click: function($event) {
                        return _vm.$router.go(-1)
                      }
                    }
                  },
                  [
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["far", "long-arrow-left"] }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "t-page-main",
        [
          _c(
            "t-page-sidebar",
            [
              _c("t-filter", {
                ref: "filterFilesSingle",
                attrs: {
                  applyFilter: _vm.applyFilter,
                  form: _vm.filterForm,
                  filterItems: _vm.filterItems,
                  filterUpdated: _vm.filtersUpdated
                },
                scopedSlots: _vm._u([
                  {
                    key: "fields",
                    fn: function() {
                      return [_c("t-files-single-filters")]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "t-page-content",
            [
              _vm.payments.length === 0 && !_vm.loadingTableData
                ? _c("t-no-data", {
                    attrs: {
                      header: "Não encontramos nenhuma transação",
                      subheader: "Não há transações"
                    }
                  })
                : _c(
                    "div",
                    [
                      _c("t-transactions-table", {
                        attrs: {
                          loading: _vm.loadingTableData,
                          payments: _vm.payments
                        },
                        on: { orderBy: _vm.orderBy }
                      }),
                      _vm.totalPages > 1
                        ? _c("t-pagination", {
                            attrs: {
                              btnClickPrev: this.prevPage,
                              btnClickNext: this.nextPage,
                              totalPages: _vm.totalPages,
                              currentPage: _vm.currentPage,
                              pagesSelected: _vm.pagesSelected,
                              pages: [5, 10, 50, 100],
                              goToPage: _vm.goToPage
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }