var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("t-page-header", {
        attrs: {
          title: _vm.$t("BillingFiles"),
          leftSize: "nine",
          rightSize: "seven",
          popup:
            "<div class='content'><p>Aqui você pode visualizar todos os arquivos utilizados para realizar o débito junto aos bancos. Os arquivos estão divididos em: Novos (arquivos que você ainda não enviou para o banco), Aguardando Retorno (arquivos que já foram enviados, mas ainda não foram processados) e Fechados (arquivos já processados). É possível clicar em cada um dos arquivos para visualizar os registros e valores contidos nele.</p><p>Os arquivos ficam disponíveis para download de 5h até as 17h (horário de Brasília).</p></div>"
        },
        scopedSlots: _vm._u(
          [
            {
              key: "notifications",
              fn: function() {
                return [_c("t-notification")]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "files-header-options" },
                    [
                      _c("div", { staticClass: "filter-files" }, [
                        _c("label", { staticClass: "filter-label" }, [
                          _vm._v("Exibir:")
                        ]),
                        _c(
                          "select",
                          {
                            staticClass: "tui tuim ui selection dropdown",
                            on: {
                              change: function($event) {
                                return _vm.reloadFilesData($event.target.value)
                              }
                            }
                          },
                          _vm._l(_vm.wallets, function(item, i) {
                            return _c("option", {
                              key: i,
                              domProps: {
                                value: item.id,
                                textContent: _vm._s(item.bankName)
                              }
                            })
                          }),
                          0
                        )
                      ]),
                      _c("t-file-upload", {
                        on: {
                          progress: _vm.uploadProgress,
                          success: _vm.onUploadFileSucess,
                          error: _vm.onUploadFileError,
                          fileChanged: _vm.onUploadFileChange
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            false
              ? {
                  key: "bottom",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "tui tuim ui top attached tabular menu"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tui tuim ui container" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    to: {
                                      name: "files",
                                      params: {
                                        type: "debit",
                                        year: _vm.getYear(),
                                        month: _vm.getMonth()
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$tc("Debit"))
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    to: {
                                      name: "files",
                                      params: {
                                        type: "billet",
                                        year: _vm.getYear(),
                                        month: _vm.getMonth()
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$tc("Billet"))
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _c(
        "t-page-main",
        [
          _c(
            "t-page-sidebar",
            { attrs: { customClass: "full-bg" } },
            _vm._l(_vm.orderedWidgetList, function(widget) {
              return _c(
                "div",
                { key: widget.year },
                [
                  _c("t-files-sidebar", {
                    attrs: { widgets: widget.monthlyMetrics, year: widget.year }
                  })
                ],
                1
              )
            }),
            0
          ),
          _c(
            "t-page-content",
            [
              !_vm.isFilesFilled() && !_vm.isLoading
                ? _c("t-no-data", {
                    attrs: {
                      header: "Não encontramos nenhum arquivo",
                      subheader: "Não há nenhum arquivo gerado"
                    }
                  })
                : [
                    _c("t-files-table-new-shipment", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.filesNewShipment.length !== 0 &&
                            _vm.isCurrentPeriod,
                          expression:
                            "filesNewShipment.length !== 0 && isCurrentPeriod"
                        }
                      ],
                      attrs: {
                        loading: _vm.loadingNewShipment,
                        files: _vm.filesNewShipment
                      }
                    }),
                    _c("t-files-table-waiting-return", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.filesWaitingReturn.length !== 0,
                          expression: "filesWaitingReturn.length !== 0"
                        }
                      ],
                      attrs: {
                        loading: _vm.loadingWaitingReturn,
                        files: _vm.filesWaitingReturn
                      }
                    }),
                    _c("t-files-table-finished", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.filesFinished.length !== 0,
                          expression: "filesFinished.length !== 0"
                        }
                      ],
                      attrs: {
                        loading: _vm.loadingFinished,
                        files: _vm.filesFinished
                      }
                    })
                  ]
            ],
            2
          )
        ],
        1
      ),
      _c("t-bar-status", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showBarStatus(),
            expression: "showBarStatus()"
          }
        ],
        attrs: {
          progress: _vm.progress,
          hasError: _vm.fileHasErrors,
          processCompleted: _vm.processCompleted,
          statusTextInfo: _vm.fileNameUpload,
          hasButton: _vm.hasButtonBarStatus()
        },
        on: {
          closeBarStatus: function($event) {
            return _vm.closeUploadStatus()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }